@use 'assets/sass/variables';

.navlink-active {
  background-color: variables.$nav-active;
}

.customers-logo {
  margin: auto;
  margin-left: 0;
}
