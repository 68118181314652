@font-face {
  font-family: CabinetGrotesk-Thin;
  src: url("CabinetGrotesk-Thin.ef816f42.woff2") format("woff2"), url("CabinetGrotesk-Thin.c7a9dbb8.woff") format("woff"), url("CabinetGrotesk-Thin.fc68d53c.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: CabinetGrotesk-Extralight;
  src: url("CabinetGrotesk-Extralight.27227bcd.woff2") format("woff2"), url("CabinetGrotesk-Extralight.b521e26f.woff") format("woff"), url("CabinetGrotesk-Extralight.3891f20c.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: CabinetGrotesk-Light;
  src: url("CabinetGrotesk-Light.05bb344b.woff2") format("woff2"), url("CabinetGrotesk-Light.71aeadf5.woff") format("woff"), url("CabinetGrotesk-Light.388efb5b.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: CabinetGrotesk-Regular;
  src: url("CabinetGrotesk-Regular.9d402eac.woff2") format("woff2"), url("CabinetGrotesk-Regular.581e521d.woff") format("woff"), url("CabinetGrotesk-Regular.51f4ac75.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: CabinetGrotesk-Medium;
  src: url("CabinetGrotesk-Medium.d4be5229.woff2") format("woff2"), url("CabinetGrotesk-Medium.db6b90bb.woff") format("woff"), url("CabinetGrotesk-Medium.de3ef6e3.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: CabinetGrotesk-Bold;
  src: url("CabinetGrotesk-Bold.db612632.woff2") format("woff2"), url("CabinetGrotesk-Bold.a365ca4f.woff") format("woff"), url("CabinetGrotesk-Bold.aef25caa.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: CabinetGrotesk-Extrabold;
  src: url("CabinetGrotesk-Extrabold.c7de3028.woff2") format("woff2"), url("CabinetGrotesk-Extrabold.cc45ac4a.woff") format("woff"), url("CabinetGrotesk-Extrabold.b0e6c07b.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: CabinetGrotesk-Black;
  src: url("CabinetGrotesk-Black.3ccec980.woff2") format("woff2"), url("CabinetGrotesk-Black.fc16288d.woff") format("woff"), url("CabinetGrotesk-Black.7403eb93.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: CabinetGrotesk-Variable;
  src: url("CabinetGrotesk-Variable.4bdddac8.woff2") format("woff2"), url("CabinetGrotesk-Variable.892fc9d1.woff") format("woff"), url("CabinetGrotesk-Variable.6160fad9.ttf") format("truetype");
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: GeneralSans-Extralight;
  src: url("GeneralSans-Extralight.50a8a19e.woff2") format("woff2"), url("GeneralSans-Extralight.b33ac30d.woff") format("woff"), url("GeneralSans-Extralight.4483b63e.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: GeneralSans-ExtralightItalic;
  src: url("GeneralSans-ExtralightItalic.c7c8fd12.woff2") format("woff2"), url("GeneralSans-ExtralightItalic.4d5a60d2.woff") format("woff"), url("GeneralSans-ExtralightItalic.c76ae09b.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: GeneralSans-Light;
  src: url("GeneralSans-Light.629f962e.woff2") format("woff2"), url("GeneralSans-Light.48aa33f3.woff") format("woff"), url("GeneralSans-Light.39a34edc.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: GeneralSans-LightItalic;
  src: url("GeneralSans-LightItalic.95b5c0e4.woff2") format("woff2"), url("GeneralSans-LightItalic.1133fcad.woff") format("woff"), url("GeneralSans-LightItalic.601f41bf.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: GeneralSans-Regular;
  src: url("GeneralSans-Regular.7611025e.woff2") format("woff2"), url("GeneralSans-Regular.d652dae4.woff") format("woff"), url("GeneralSans-Regular.62489a53.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: GeneralSans-Italic;
  src: url("GeneralSans-Italic.1e0b3f79.woff2") format("woff2"), url("GeneralSans-Italic.edbacd03.woff") format("woff"), url("GeneralSans-Italic.c2141b69.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: GeneralSans-Medium;
  src: url("GeneralSans-Medium.385d10ef.woff2") format("woff2"), url("GeneralSans-Medium.3db2032c.woff") format("woff"), url("GeneralSans-Medium.496b905a.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: GeneralSans-MediumItalic;
  src: url("GeneralSans-MediumItalic.14022b0a.woff2") format("woff2"), url("GeneralSans-MediumItalic.ad045d41.woff") format("woff"), url("GeneralSans-MediumItalic.4ff02de8.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: GeneralSans-Semibold;
  src: url("GeneralSans-Semibold.c9b42dc5.woff2") format("woff2"), url("GeneralSans-Semibold.bf4c5428.woff") format("woff"), url("GeneralSans-Semibold.24ac9ba4.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: GeneralSans-SemiboldItalic;
  src: url("GeneralSans-SemiboldItalic.f6755eb9.woff2") format("woff2"), url("GeneralSans-SemiboldItalic.9d89cbcd.woff") format("woff"), url("GeneralSans-SemiboldItalic.5c5b0310.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: GeneralSans-Bold;
  src: url("GeneralSans-Bold.bc1eb702.woff2") format("woff2"), url("GeneralSans-Bold.6afbfc5d.woff") format("woff"), url("GeneralSans-Bold.75d1d4cc.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: GeneralSans-BoldItalic;
  src: url("GeneralSans-BoldItalic.2d765d8f.woff2") format("woff2"), url("GeneralSans-BoldItalic.ff39d938.woff") format("woff"), url("GeneralSans-BoldItalic.a0abe114.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: GeneralSans-Variable;
  src: url("GeneralSans-Variable.8f5cd529.woff2") format("woff2"), url("GeneralSans-Variable.9478aac0.woff") format("woff"), url("GeneralSans-Variable.ed56b7f8.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: GeneralSans-VariableItalic;
  src: url("GeneralSans-VariableItalic.ef6a33c8.woff2") format("woff2"), url("GeneralSans-VariableItalic.09904a6c.woff") format("woff"), url("GeneralSans-VariableItalic.392f5d1d.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: italic;
}

input[type="search"]::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

input[type="search"]::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

input[type="search"]::-webkit-search-decoration {
  display: none;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

input[type="search"]::-webkit-search-results-button {
  display: none;
}

input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

@media screen and (prefers-color-scheme: light) {
  :root {
    --jkl-footer-background-color: #000;
    --jkl-footer-text-color: #f9f9f9;
  }
}

[data-theme="light"] {
  --jkl-footer-background-color: #000;
  --jkl-footer-text-color: #f9f9f9;
}

@media screen and (prefers-color-scheme: dark) {
  :root {
    --jkl-footer-background-color: #fff;
    --jkl-footer-text-color: #1b1917;
  }
}

[data-theme="dark"] {
  --jkl-footer-background-color: #fff;
  --jkl-footer-text-color: #1b1917;
}

.jkl-footer {
  --jkl-logo-color: var(--jkl-footer-text-color);
  --jkl-link-color: var(--jkl-footer-text-color);
  background-color: var(--jkl-footer-background-color);
  color: var(--jkl-footer-text-color);
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
  min-height: 9.375rem;
  padding: 1.5rem;
  display: flex;
}

@media (width >= 680px) {
  :not([data-compactlayout]) > .jkl-footer {
    gap: 2.5rem;
    min-height: 8.375rem;
    padding: 2.5rem;
  }
}

@media (width >= 1200px) {
  :not([data-compactlayout]) > .jkl-footer {
    flex-direction: row;
  }
}

.jkl-footer__logo {
  color: inherit;
  width: 12.5rem;
  min-width: 12.5rem;
}

.jkl-footer__address {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
}

@media (width >= 680px) {
  .jkl-footer__address {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

.jkl-footer__links {
  color: inherit;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
}

@media (width >= 680px) {
  .jkl-footer__links {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

.jkl-footer__links ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}

.jkl-footer__links ul li {
  margin-bottom: 1rem;
  display: block;
}

@media (width >= 680px) {
  :not([data-compactlayout]) > .jkl-footer > .jkl-footer__links ul li {
    margin-right: 1.5rem;
    display: inline-block;
  }
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

abbr[title] {
  text-decoration: underline;
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
  line-height: 1;
  display: block;
}

table {
  border-collapse: collapse;
}

table, thead, tbody, tr, td, th {
  margin: 0;
  padding: 0;
}

.jkl-title {
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 2.75rem;
}

@media (width >= 680px) {
  .jkl-title {
    font-size: 3.5rem;
    font-weight: 400;
    line-height: 4rem;
  }
}

[data-compactlayout] .jkl-title {
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 2.75rem;
}

.jkl-heading-1 {
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 2rem;
}

@media (width >= 680px) {
  .jkl-heading-1 {
    font-size: 2.25rem;
    font-weight: 400;
    line-height: 2.75rem;
  }
}

[data-compactlayout] .jkl-heading-1 {
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 2rem;
}

.jkl-heading-2 {
  font-size: 1.4375rem;
  font-weight: 400;
  line-height: 2rem;
}

@media (width >= 680px) {
  .jkl-heading-2 {
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 2.5rem;
  }
}

[data-compactlayout] .jkl-heading-2 {
  font-size: 1.4375rem;
  font-weight: 400;
  line-height: 2rem;
}

.jkl-heading-3 {
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

@media (width >= 680px) {
  .jkl-heading-3 {
    font-size: 1.5625rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

[data-compactlayout] .jkl-heading-3 {
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.jkl-heading-4 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
}

@media (width >= 680px) {
  .jkl-heading-4 {
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1.75rem;
  }
}

[data-compactlayout] .jkl-heading-4 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.jkl-heading-5 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
}

@media (width >= 680px) {
  .jkl-heading-5 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

[data-compactlayout] .jkl-heading-5 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.jkl-body {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
}

@media (width >= 680px) {
  .jkl-body {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

[data-compactlayout] .jkl-body {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
}

.jkl-bold {
  letter-spacing: -.014em;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

@media (width >= 680px) {
  .jkl-bold {
    letter-spacing: -.014em;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 2rem;
  }
}

[data-compactlayout] .jkl-bold {
  letter-spacing: -.014em;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.jkl-small {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

@media (width >= 680px) {
  .jkl-small {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

[data-compactlayout] .jkl-small {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

@media screen and (prefers-color-scheme: light) {
  :root {
    --link-color: #1b1917;
    --link-hover-color: #636060;
    --link-active-color: #1b1917;
  }
}

[data-theme="light"] {
  --link-color: #1b1917;
  --link-hover-color: #636060;
  --link-active-color: #1b1917;
}

@media screen and (prefers-color-scheme: dark) {
  :root {
    --link-color: #f9f9f9;
    --link-hover-color: #c8c5c3;
    --link-active-color: #f9f9f9;
  }
}

[data-theme="dark"] {
  --link-color: #f9f9f9;
  --link-hover-color: #c8c5c3;
  --link-active-color: #f9f9f9;
}

.jkl-link {
  color: var(--link-color);
  background-image: linear-gradient(currentColor 0% 100%);
  background-position: 0 calc(100% - .0625rem);
  background-repeat: repeat-x;
  background-size: .0625rem .0625rem;
  outline: none;
  text-decoration: none;
  transition-property: background-size, background-position, color;
  transition-duration: 75ms;
  transition-timing-function: ease;
}

.jkl-link:hover:not(:focus) {
  color: var(--link-hover-color);
  background-image: linear-gradient(to bottom, var(--link-hover-color) 0%, var(--link-hover-color) 100%);
  background-position: 0 100%;
  background-size: .125rem .125rem;
}

.jkl-link:hover .jkl-link:focus {
  color: var(--link-color);
  background-image: linear-gradient(to bottom, var(--link-color) 0%, var(--link-color) 100%);
}

html:not([data-mousenavigation]) .jkl-link:focus {
  letter-spacing: -.014em;
  border-style: solid;
  border-width: .0625rem;
  border-image-slice: 1;
  border-image-source: linear-gradient(to bottom, var(--link-active-color) 0%, var(--link-active-color) 100%);
  font-weight: 700;
}

.jkl-link:active {
  background-position: 0 calc(100% - .0625rem);
  background-size: .0625rem .0625rem;
}

.jkl-link[target="_blank"]:after, .jkl-link--external:after {
  content: "↗";
  content: "↗" / "";
  alt: " ";
}

@media screen and (forced-colors: active) {
  .jkl-link {
    outline: revert;
    -webkit-text-decoration: revert;
    -webkit-text-decoration: revert;
    text-decoration: revert;
  }
}

.jkl-nav-link {
  color: var(--link-color);
  box-sizing: border-box;
  border-style: none;
  outline: 0;
  font-weight: 400;
  text-decoration: none;
  position: relative;
}

.jkl-nav-link:active, .jkl-nav-link:hover, .jkl-nav-link:focus {
  outline: 0;
}

@media screen and (forced-colors: active) {
  .jkl-nav-link {
    outline: revert;
    border-style: revert;
    outline-style: revert;
  }

  .jkl-nav-link:active, .jkl-nav-link:hover, .jkl-nav-link:focus {
    outline: revert;
    outline-style: revert;
  }
}

.jkl-nav-link:after {
  content: "→";
  content: "→" / "";
  alt: " ";
  margin-left: -.2em;
  padding-left: .35em;
  padding-right: .35em;
  transition-property: padding-left, padding-right;
  transition-duration: .15s;
  transition-timing-function: ease;
  display: inline;
}

.jkl-nav-link:hover:not(:focus) {
  color: var(--link-hover-color);
}

.jkl-nav-link:hover:not(:focus):after {
  padding-left: .7em;
  padding-right: 0;
}

html:not([data-mousenavigation]) .jkl-nav-link:focus {
  border-style: solid;
  border-width: .0625rem;
  border-image-slice: 1;
  border-image-source: linear-gradient(to bottom, var(--link-color) 0%, var(--link-color) 100%);
  letter-spacing: -.014em;
  font-weight: 700;
}

html:not([data-mousenavigation]) .jkl-nav-link:focus:after {
  padding-left: .7em;
  padding-right: 0;
}

.jkl-nav-link--active {
  letter-spacing: -.014em;
  font-weight: 700;
}

.jkl-nav-link--back {
  margin-left: -.3em;
  padding-left: .3em;
  padding-right: 0;
}

.jkl-nav-link--back:after {
  display: none;
}

.jkl-nav-link--back:before {
  content: "←";
  content: "←" / "";
  alt: " ";
  padding-right: .25rem;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: ease;
  display: inline-block;
  transform: translateX(0);
}

.jkl-nav-link--back:hover:before, html:not([data-mousenavigation]) .jkl-nav-link--back:focus:before {
  transform: translateX(-33%);
}

@media screen and (prefers-color-scheme: light) {
  :root {
    --label-color: #1b1917;
    --label-support-color: #636060;
    --label-error-color: #1b1917;
  }
}

[data-theme="light"] {
  --label-color: #1b1917;
  --label-support-color: #636060;
  --label-error-color: #1b1917;
}

@media screen and (prefers-color-scheme: dark) {
  :root {
    --label-color: #f9f9f9;
    --label-support-color: #c8c5c3;
    --label-error-color: #f9f9f9;
  }
}

[data-theme="dark"] {
  --label-color: #f9f9f9;
  --label-support-color: #c8c5c3;
  --label-error-color: #f9f9f9;
}

.jkl-dormant-form-support-label {
  opacity: 0;
  pointer-events: none;
  display: none;
}

.jkl-form-support-label {
  color: var(--label-support-color);
  margin-top: .5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  transition: color .4s .15s;
  display: block;
}

@media (width >= 680px) {
  .jkl-form-support-label {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

.jkl-form-support-label__icon {
  opacity: 0;
  height: 1.5rem;
  margin-bottom: -.375rem;
  margin-right: -1.5rem;
}

@media screen and (forced-colors: active) {
  .jkl-form-support-label__icon, .jkl-form-support-label__icon svg, .jkl-form-support-label__icon path {
    stroke: canvastext;
    fill: canvas;
  }
}

.jkl-form-support-label--compact .jkl-form-support-label__icon {
  height: 1.375rem;
  margin-bottom: -.3125rem;
  margin-right: -1.375rem;
}

.jkl-form-support-label--error {
  color: var(--label-error-color);
}

.jkl-form-support-label--error .jkl-form-support-label__icon {
  animation: .4s cubic-bezier(0, 0, .3, 1) .25s forwards jkl-support-label-icon-entrance;
}

.jkl-form-support-label--sr-only {
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.jkl-label {
  margin-left: initial;
  color: var(--label-color);
  display: block;
}

.jkl-label--large {
  margin-bottom: 1rem;
  margin-left: -.0625rem;
  font-size: 1.4375rem;
  font-weight: 400;
  line-height: 2rem;
}

@media (width >= 680px) {
  .jkl-label--large {
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 2.5rem;
  }
}

.jkl-label--medium {
  margin-bottom: .5rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
}

@media (width >= 680px) {
  .jkl-label--medium {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

.jkl-label--small {
  margin-bottom: .25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

@media (width >= 680px) {
  .jkl-label--small {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

.jkl-label--sr-only {
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.jkl-label--compact.jkl-label--small, [data-compactlayout] .jkl-label.jkl-label--small {
  margin-bottom: .25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.jkl-label--compact.jkl-label--medium, [data-compactlayout] .jkl-label.jkl-label--medium {
  margin-bottom: .5rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
}

.jkl-label--compact.jkl-label--large, [data-compactlayout] .jkl-label.jkl-label--large {
  margin-bottom: .75rem;
  font-size: 1.4375rem;
  font-weight: 400;
  line-height: 2rem;
}

@keyframes jkl-support-label-icon-entrance {
  0% {
    opacity: 0;
    margin-right: 0;
    transform: scale(1);
  }

  30% {
    margin-right: .5rem;
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale(1.1);
  }

  85% {
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    margin-right: .5rem;
    transform: scale(1);
  }
}

.jkl-spacing-3xs--all {
  margin: .125rem;
}

.jkl-spacing-3xs--top {
  margin-top: .125rem;
}

.jkl-spacing-3xs--bottom {
  margin-bottom: .125rem;
}

.jkl-spacing-3xs--left {
  margin-left: .125rem;
}

.jkl-spacing-3xs--right {
  margin-right: .125rem;
}

.jkl-spacing-2xs--all {
  margin: .25rem;
}

.jkl-spacing-2xs--top {
  margin-top: .25rem;
}

.jkl-spacing-2xs--bottom {
  margin-bottom: .25rem;
}

.jkl-spacing-2xs--left {
  margin-left: .25rem;
}

.jkl-spacing-2xs--right {
  margin-right: .25rem;
}

.jkl-spacing-xs--all {
  margin: .5rem;
}

.jkl-spacing-xs--top {
  margin-top: .5rem;
}

.jkl-spacing-xs--bottom {
  margin-bottom: .5rem;
}

.jkl-spacing-xs--left {
  margin-left: .5rem;
}

.jkl-spacing-xs--right {
  margin-right: .5rem;
}

.jkl-spacing-s--all {
  margin: .75rem;
}

.jkl-spacing-s--top {
  margin-top: .75rem;
}

.jkl-spacing-s--bottom {
  margin-bottom: .75rem;
}

.jkl-spacing-s--left {
  margin-left: .75rem;
}

.jkl-spacing-s--right {
  margin-right: .75rem;
}

.jkl-spacing-m--all {
  margin: 1rem;
}

.jkl-spacing-m--top {
  margin-top: 1rem;
}

.jkl-spacing-m--bottom {
  margin-bottom: 1rem;
}

.jkl-spacing-m--left {
  margin-left: 1rem;
}

.jkl-spacing-m--right {
  margin-right: 1rem;
}

.jkl-spacing-l--all {
  margin: 1.5rem;
}

.jkl-spacing-l--top {
  margin-top: 1.5rem;
}

.jkl-spacing-l--bottom {
  margin-bottom: 1.5rem;
}

.jkl-spacing-l--left {
  margin-left: 1.5rem;
}

.jkl-spacing-l--right {
  margin-right: 1.5rem;
}

.jkl-spacing-xl--all {
  margin: 2.5rem;
}

.jkl-spacing-xl--top {
  margin-top: 2.5rem;
}

.jkl-spacing-xl--bottom {
  margin-bottom: 2.5rem;
}

.jkl-spacing-xl--left {
  margin-left: 2.5rem;
}

.jkl-spacing-xl--right {
  margin-right: 2.5rem;
}

.jkl-spacing-2xl--all {
  margin: 4rem;
}

.jkl-spacing-2xl--top {
  margin-top: 4rem;
}

.jkl-spacing-2xl--bottom {
  margin-bottom: 4rem;
}

.jkl-spacing-2xl--left {
  margin-left: 4rem;
}

.jkl-spacing-2xl--right {
  margin-right: 4rem;
}

.jkl-spacing-3xl--all {
  margin: 6.5rem;
}

.jkl-spacing-3xl--top {
  margin-top: 6.5rem;
}

.jkl-spacing-3xl--bottom {
  margin-bottom: 6.5rem;
}

.jkl-spacing-3xl--left {
  margin-left: 6.5rem;
}

.jkl-spacing-3xl--right {
  margin-right: 6.5rem;
}

.jkl-spacing-4xl--all {
  margin: 10.5rem;
}

.jkl-spacing-4xl--top {
  margin-top: 10.5rem;
}

.jkl-spacing-4xl--bottom {
  margin-bottom: 10.5rem;
}

.jkl-spacing-4xl--left {
  margin-left: 10.5rem;
}

.jkl-spacing-4xl--right {
  margin-right: 10.5rem;
}

.jkl-sr-only {
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.jkl-sr-only--focusable:focus, .jkl-sr-only--focusable:active {
  clip: auto !important;
  clip-path: none !important;
  white-space: normal !important;
  width: auto !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
}

.jkl-color-hvit {
  color: #fff;
}

.jkl-bgcolor-hvit {
  background-color: #fff;
}

.jkl-color-snohvit {
  color: #f9f9f9;
}

.jkl-bgcolor-snohvit {
  background-color: #f9f9f9;
}

.jkl-color-sand {
  color: #f4f2ef;
}

.jkl-bgcolor-sand {
  background-color: #f4f2ef;
}

.jkl-color-dis {
  color: #ece9e5;
}

.jkl-bgcolor-dis {
  background-color: #ece9e5;
}

.jkl-color-varde {
  color: #e0dbd4;
}

.jkl-bgcolor-varde {
  background-color: #e0dbd4;
}

.jkl-color-svaberg {
  color: #c8c5c3;
}

.jkl-bgcolor-svaberg {
  background-color: #c8c5c3;
}

.jkl-color-stein {
  color: #636060;
}

.jkl-bgcolor-stein {
  background-color: #636060;
}

.jkl-color-fjellgra {
  color: #444141;
}

.jkl-bgcolor-fjellgra {
  background-color: #444141;
}

.jkl-color-skifer {
  color: #313030;
}

.jkl-bgcolor-skifer {
  background-color: #313030;
}

.jkl-color-granitt {
  color: #1b1917;
}

.jkl-bgcolor-granitt {
  background-color: #1b1917;
}

.jkl-color-svart {
  color: #000;
}

.jkl-bgcolor-svart {
  background-color: #000;
}

.jkl-color-suksess {
  color: #287e68;
}

.jkl-bgcolor-suksess {
  background-color: #287e68;
}

.jkl-color-info {
  color: #000afa;
}

.jkl-bgcolor-info {
  background-color: #000afa;
}

.jkl-color-advarsel {
  color: #fbb15b;
}

.jkl-bgcolor-advarsel {
  background-color: #fbb15b;
}

.jkl-color-feil {
  color: #aa1f23;
}

.jkl-bgcolor-feil {
  background-color: #aa1f23;
}

.jkl-color-suksess--darkbg {
  color: #5effa0;
}

.jkl-bgcolor-suksess--darkbg {
  background-color: #5effa0;
}

.jkl-color-info--darkbg {
  color: #00faff;
}

.jkl-bgcolor-info--darkbg {
  background-color: #00faff;
}

.jkl-color-advarsel--darkbg {
  color: #fec97b;
}

.jkl-bgcolor-advarsel--darkbg {
  background-color: #fec97b;
}

.jkl-color-feil--darkbg {
  color: #ff8b79;
}

.jkl-bgcolor-feil--darkbg {
  background-color: #ff8b79;
}

:root {
  --jkl-icon-width: 20px;
}

.jkl-icon {
  width: var(--jkl-icon-width);
}

.jkl-icon--inherit {
  width: initial;
  height: 1em;
}

.jkl-icon--small {
  --jkl-icon-width: 20px;
}

.jkl-icon--medium {
  --jkl-icon-width: 30px;
}

.jkl-icon--large {
  --jkl-icon-width: 40px;
}

@media screen and (forced-colors: active) {
  .jkl-icon, .jkl-icon svg, .jkl-icon path {
    stroke: canvastext;
  }
}

@media screen and (prefers-color-scheme: light) {
  :root {
    --jkl-background-color: #f9f9f9;
    --jkl-color: #1b1917;
  }
}

[data-theme="light"] {
  --jkl-background-color: #f9f9f9;
  --jkl-color: #1b1917;
}

@media screen and (prefers-color-scheme: dark) {
  :root {
    --jkl-background-color: #1b1917;
    --jkl-color: #f9f9f9;
  }
}

[data-theme="dark"] {
  --jkl-background-color: #1b1917;
  --jkl-color: #f9f9f9;
}

.jkl {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--jkl-background-color);
  color: var(--jkl-color);
  font-family: Fremtind Grotesk, Calibri, Arial, sans-serif;
}

@media screen and (prefers-reduced-motion: reduce) {
  *, :after, :before {
    transition: none !important;
    animation-duration: 0s !important;
    animation-delay: 0s !important;
  }
}

.passing-icon {
  align-self: center;
}

@media screen and (prefers-color-scheme: light) {
  :root {
    --jkl-button-border-color: #1b1917;
    --jkl-button-text-color: #1b1917;
    --jkl-button-background-color: transparent;
    --jkl-button-focus-color: #1b1917;
    --jkl-button-primary-text-color: #f9f9f9;
    --jkl-button-primary-background-color: #1b1917;
  }
}

[data-theme="light"] {
  --jkl-button-border-color: #1b1917;
  --jkl-button-text-color: #1b1917;
  --jkl-button-background-color: transparent;
  --jkl-button-focus-color: #1b1917;
  --jkl-button-primary-text-color: #f9f9f9;
  --jkl-button-primary-background-color: #1b1917;
}

@media screen and (prefers-color-scheme: dark) {
  :root {
    --jkl-button-border-color: #f9f9f9;
    --jkl-button-text-color: #f9f9f9;
    --jkl-button-background-color: transparent;
    --jkl-button-focus-color: #f9f9f9;
    --jkl-button-primary-text-color: #1b1917;
    --jkl-button-primary-background-color: #f9f9f9;
  }
}

[data-theme="dark"] {
  --jkl-button-border-color: #f9f9f9;
  --jkl-button-text-color: #f9f9f9;
  --jkl-button-background-color: transparent;
  --jkl-button-focus-color: #f9f9f9;
  --jkl-button-primary-text-color: #1b1917;
  --jkl-button-primary-background-color: #f9f9f9;
}

a.jkl-button {
  text-decoration: none;
}

.jkl-button {
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  transform-origin: 50% 90%;
  border-style: none;
  outline: 0;
  justify-content: center;
  min-width: 6.5rem;
  height: 3rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 3rem;
  transition-property: transform, background-color;
  transition-duration: .15s;
  transition-timing-function: ease;
  display: inline-flex;
  position: relative;
  overflow: visible;
}

.jkl-button:active, .jkl-button:hover, .jkl-button:focus {
  outline: 0;
}

@media screen and (forced-colors: active) {
  .jkl-button {
    outline: revert;
    border-style: revert;
    outline-style: revert;
  }

  .jkl-button:active, .jkl-button:hover, .jkl-button:focus {
    outline: revert;
    outline-style: revert;
  }
}

html:not([data-mousenavigation]):not([data-touchnavigation]) .jkl-button:focus, html:not([data-touchnavigation]) .jkl-button:hover {
  transform: scale(1.05);
}

html:not([data-mousenavigation]) .jkl-button:active, html:not([data-touchnavigation]) .jkl-button:active, .jkl-button:active {
  transform: scale(1);
}

html[data-touchnavigation] .jkl-button--pressed {
  -webkit-tap-highlight-color: #0000;
  transform: scale(.95);
}

.jkl-button__content {
  height: 3rem;
  overflow: hidden;
}

.jkl-button__content--compact {
  height: 2.25rem;
}

.jkl-button__content--compact .jkl-button__slider--show-loader {
  transform: translateY(-51%);
}

.jkl-button__content--compact .jkl-button__loader {
  height: 2.25rem;
}

.jkl-button__slider {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: ease;
}

.jkl-button__slider--show-loader {
  transform: translateY(-51%);
}

.jkl-button__loader {
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding: 0;
  display: flex;
}

.jkl-button--primary, .jkl-button--secondary {
  border: solid .0625rem var(--jkl-button-border-color);
  border-radius: 999px;
  padding: 0 1.5rem;
}

.jkl-button--primary:after, .jkl-button--secondary:after {
  box-shadow: none;
  content: "";
  border: .125rem solid #0000;
  border-radius: 99px;
  transition: box-shadow .1s cubic-bezier(.6, .2, .35, 1);
  position: absolute;
  inset: -.3125rem;
}

.jkl-button--primary:before, .jkl-button--secondary:before {
  content: "";
  border-radius: 999px;
  position: absolute;
  inset: 0;
}

html:not([data-mousenavigation]):not([data-touchnavigation]) .jkl-button--primary:focus:after, html:not([data-mousenavigation]):not([data-touchnavigation]) .jkl-button--secondary:focus:after {
  box-shadow: 0 0 0 .125rem var(--jkl-button-focus-color);
}

html[data-touchnavigation] .jkl-button--primary.jkl-button--pressed:before, html[data-touchnavigation] .jkl-button--secondary.jkl-button--pressed:before {
  animation: .4s cubic-bezier(0, 0, .375, 1.17) jkl-flash-uc0zxs2;
}

.jkl-button--primary {
  background-color: var(--jkl-button-primary-background-color);
  color: var(--jkl-button-primary-text-color);
}

.jkl-button--primary:hover, html:not([data-mousenavigation]):not([data-touchnavigation]) .jkl-button--primary:focus, html[data-touchnavigation] .jkl-button--primary.jkl-button--pressed {
  background-color: var(--jkl-button-focus-color);
  border-color: var(--jkl-button-focus-color);
}

.jkl-button--secondary {
  background-color: var(--jkl-button-background-color);
  color: var(--jkl-button-text-color);
}

.jkl-button--secondary:hover, html:not([data-mousenavigation]):not([data-touchnavigation]) .jkl-button--secondary:focus, html[data-touchnavigation] .jkl-button--secondary.jkl-button--pressed {
  box-shadow: inset 0 0 0 1px var(--jkl-button-border-color), inset 0 0 0 1px var(--jkl-button-border-color);
}

.jkl-button--tertiary {
  border-bottom: solid .0625rem var(--jkl-button-border-color);
  color: var(--jkl-button-text-color);
  min-width: unset;
  background-color: #0000;
  border-top: .0625rem solid #0000;
  padding: 0;
  transition: transform .1s cubic-bezier(.6, .2, .35, 1), border .1s cubic-bezier(.6, .2, .35, 1);
}

.jkl-button--tertiary:before {
  content: "";
  pointer-events: none;
  left: var(--jkl-touch-xcoord, 50%);
  top: var(--jkl-touch-ycoord, 50%);
  background-color: #0000;
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  display: block;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
}

.jkl-button--tertiary:after {
  box-shadow: none;
  content: "";
  border: .125rem solid #0000;
  transition: box-shadow .1s cubic-bezier(.6, .2, .35, 1);
  position: absolute;
  inset: -.0625rem;
}

html[data-touchnavigation] .jkl-button--tertiary.jkl-button--pressed:before {
  animation: .4s cubic-bezier(0, 0, .375, 1.17) jkl-tertiary-flash-uc0zxs5;
}

html:not([data-mousenavigation]):not([data-touchnavigation]) .jkl-button--tertiary:focus {
  border: none;
}

html:not([data-mousenavigation]):not([data-touchnavigation]) .jkl-button--tertiary:focus:after {
  box-shadow: 0 0 0 .125rem var(--jkl-button-focus-color);
}

@media screen and (forced-colors: active) {
  html:not([data-mousenavigation]):not([data-touchnavigation]) .jkl-button--tertiary:focus {
    border: revert;
  }
}

.jkl-button--tertiary:hover, html:not([data-mousenavigation]):not([data-touchnavigation]) .jkl-button--tertiary:focus, html[data-touchnavigation] .jkl-button--tertiary.jkl-button--pressed {
  border-bottom-color: var(--jkl-button-focus-color);
  color: var(--jkl-button-focus-color);
  border-bottom-width: .125rem;
}

.jkl-button--compact, [data-compactlayout] .jkl-button {
  min-width: 5rem;
  height: 2.25rem;
  font-size: .875rem;
  line-height: 2.25rem;
}

.jkl-button--compact.jkl-button--tertiary, [data-compactlayout] .jkl-button.jkl-button--tertiary {
  min-width: unset;
}

@media screen and (forced-colors: active) {
  .jkl-button.jkl-button--primary:not(a), .jkl-button.jkl-button--secondary:not(a), .jkl-button.jkl-button--primary:hover:not(a), .jkl-button.jkl-button--secondary:hover:not(a), .jkl-button.jkl-button--primary:focus:not(a), .jkl-button.jkl-button--secondary:focus:not(a) {
    border-color: buttontext;
  }

  .jkl-button .jkl-loader__dot {
    background-color: buttontext;
  }

  .jkl-button.jkl-button--secondary:after {
    border: none;
  }

  .jkl-button.jkl-button--tertiary {
    outline-offset: .125rem;
  }

  .jkl-button.jkl-button--tertiary, .jkl-button.jkl-button--tertiary:after {
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
  }
}

@keyframes jkl-flash-uc0zxs2 {
  0% {
    box-shadow: 0 0 0 0 var(--jkl-button-focus-color);
    opacity: .5;
  }

  100% {
    box-shadow: 0 0 0 1rem var(--jkl-button-focus-color);
    opacity: 0;
  }
}

@keyframes jkl-tertiary-flash-uc0zxs5 {
  0% {
    box-shadow: 0 0 0 0 var(--jkl-button-focus-color);
    background-color: var(--jkl-button-focus-color);
    opacity: .5;
  }

  100% {
    box-shadow: 0 0 0 2.5rem var(--jkl-button-focus-color);
    background-color: var(--jkl-button-focus-color);
    opacity: 0;
  }
}

.jkl-loader {
  display: flex;
}

.jkl-loader--inline {
  display: inline-flex;
}

.jkl-loader__dot {
  transform-origin: center;
  background-color: currentColor;
  width: 1.125rem;
  height: 1.125rem;
  animation: none;
  display: block;
}

.jkl-loader__dot--left {
  margin-right: 2.25rem;
  animation-name: jkl-loader-left-spin-u9m75r6;
}

.jkl-loader__dot--middle {
  margin-right: 2.5rem;
  animation-name: jkl-loader-middle-spin-u9m75s4;
}

.jkl-loader__dot--right {
  animation-name: jkl-loader-right-spin-u9m75st;
}

@media screen and (forced-colors: active) {
  .jkl-loader__dot {
    background-color: canvastext;
  }
}

.jkl-loader--medium > .jkl-loader__dot {
  width: .4375rem;
  height: .4375rem;
}

.jkl-loader--medium > .jkl-loader__dot--left {
  margin-right: .675rem;
}

.jkl-loader--medium > .jkl-loader__dot--middle {
  margin-right: .75rem;
}

.jkl-loader--small > .jkl-loader__dot {
  width: .3125rem;
  height: .3125rem;
}

.jkl-loader--small > .jkl-loader__dot--left {
  margin-right: .45rem;
}

.jkl-loader--small > .jkl-loader__dot--middle {
  margin-right: .5rem;
}

@keyframes jkl-loader-left-spin-u9m75r6 {
  0% {
    transform: rotate(0)scale(0);
  }

  30% {
    transform: rotate(90deg)scale(1);
  }

  70% {
    transform: rotate(180deg)scale(0);
  }

  100% {
    transform: rotate(180deg)scale(0);
  }
}

@keyframes jkl-loader-middle-spin-u9m75s4 {
  0% {
    transform: rotate(20deg)scale(0);
  }

  10% {
    transform: rotate(20deg)scale(0);
  }

  40% {
    transform: rotate(110deg)scale(1.4);
  }

  85% {
    transform: rotate(200deg)scale(0);
  }

  100% {
    transform: rotate(200deg)scale(0);
  }
}

@keyframes jkl-loader-right-spin-u9m75st {
  0% {
    transform: rotate(40deg)scale(0);
  }

  20% {
    transform: rotate(40deg)scale(0);
  }

  50% {
    transform: rotate(130deg)scale(1);
  }

  100% {
    transform: rotate(220deg)scale(0);
  }
}

.navlink-active {
  background-color: #0000001a !important;
}

.customers-logo {
  margin: auto auto auto 0;
}

:root, [data-layout-density="comfortable"], [data-density="comfortable"] {
  --jkl-alert-message-icon-height: 1.125rem;
  --jkl-alert-message-icon-padding: .4375rem 0 0 0;
  --jkl-alert-message-content-padding: 1.5rem;
  --jkl-alert-message-dismiss-button-size: 2.75rem;
  --jkl-alert-message-dismiss-button-margin: -.40625rem -1.125rem -.40625rem auto;
  --jkl-alert-message-message-margin: 0 1rem;
  --jkl-alert-message-message-font-size: var(--jkl-body-font-size);
  --jkl-alert-message-message-line-height: var(--jkl-body-line-height);
  --jkl-alert-message-message-font-weight: var(--jkl-body-font-weight);
}

[data-layout-density="compact"], [data-density="compact"] {
  --jkl-alert-message-icon-height: .75rem;
  --jkl-alert-message-icon-padding: .3125rem 0 0 .125rem;
  --jkl-alert-message-content-padding: .5rem;
  --jkl-alert-message-dismiss-button-size: 2rem;
  --jkl-alert-message-dismiss-button-margin: -.1875rem -.375rem -.28125rem auto;
  --jkl-alert-message-message-margin: 0 .75rem;
  --jkl-alert-message-message-font-size: var(--jkl-small-font-size);
  --jkl-alert-message-message-line-height: var(--jkl-small-line-height);
  --jkl-alert-message-message-font-weight: var(--jkl-small-font-weight);
}

.jkl-alert-message {
  color: #1b1917;
  box-sizing: border-box;
  background-color: #f9f9f9;
  width: 100%;
}

.jkl-alert-message__content {
  box-sizing: border-box;
  padding: var(--jkl-alert-message-content-padding);
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.jkl-alert-message__icon {
  height: var(--jkl-alert-message-icon-height);
  margin: var(--jkl-alert-message-icon-padding);
  flex-shrink: 0;
  align-self: flex-start;
}

@media screen and (forced-colors: active) {
  .jkl-alert-message__icon, .jkl-alert-message__icon svg, .jkl-alert-message__icon path {
    stroke: canvastext;
  }
}

.jkl-alert-message__message {
  font-size: var(--jkl-alert-message-message-font-size);
  line-height: var(--jkl-alert-message-message-line-height);
  font-weight: var(--jkl-alert-message-message-font-weight);
  margin: var(--jkl-alert-message-message-margin);
}

.jkl-alert-message__dismiss-button {
  cursor: pointer;
  width: var(--jkl-alert-message-dismiss-button-size);
  height: var(--jkl-alert-message-dismiss-button-size);
  margin: var(--jkl-alert-message-dismiss-button-margin);
  color: inherit;
  background-color: #0000;
  border-style: none;
  border-radius: .1875rem;
  outline: 0;
  flex-shrink: 0;
  align-self: flex-start;
  padding: 0;
}

.jkl-alert-message__dismiss-button:active, .jkl-alert-message__dismiss-button:hover, .jkl-alert-message__dismiss-button:focus {
  outline: 0;
}

@media screen and (forced-colors: active) {
  .jkl-alert-message__dismiss-button {
    outline: revert;
    border-style: revert;
    outline-style: revert;
  }

  .jkl-alert-message__dismiss-button:active, .jkl-alert-message__dismiss-button:hover, .jkl-alert-message__dismiss-button:focus {
    outline: revert;
    outline-style: revert;
  }

  .jkl-alert-message__dismiss-button, .jkl-alert-message__dismiss-button svg, .jkl-alert-message__dismiss-button path {
    stroke: buttontext;
  }

  .jkl-alert-message__dismiss-button {
    background-color: buttonface;
  }
}

.jkl-alert-message__dismiss-button:hover {
  color: #636060;
}

@media screen and (forced-colors: active) {
  .jkl-alert-message__dismiss-button:hover, .jkl-alert-message__dismiss-button:hover svg, .jkl-alert-message__dismiss-button:hover path {
    stroke: buttontext;
  }
}

html:not([data-mousenavigation]):not([data-touchnavigation]) .jkl-alert-message__dismiss-button:focus {
  outline: 2px solid #1b1917;
}

@media screen and (forced-colors: active) {
  html:not([data-mousenavigation]):not([data-touchnavigation]) .jkl-alert-message__dismiss-button:focus {
    outline-offset: 2px;
    outline: 2px solid buttontext;
  }
}

.jkl-alert-message__message, .jkl-alert-message__message:last-child {
  margin-bottom: 0;
}

.jkl-alert-message--dismissed {
  visibility: hidden;
  transition: visibility 0s .4s;
  animation: .4s ease-in forwards jkl-dismiss-u58pgjv;
}

.jkl-alert-message--info {
  background-color: #d9ddff;
}

.jkl-alert-message--warning {
  background-color: #fdeab9;
}

.jkl-alert-message--error {
  background-color: #ffc9b2;
}

.jkl-alert-message--success {
  background-color: #c8ecd2;
}

@media screen and (forced-colors: active) {
  .jkl-alert-message {
    border: 2px solid canvastext;
  }

  .jkl-alert-message--info {
    border-style: dotted;
  }

  .jkl-alert-message--warning {
    border-style: dashed;
  }

  .jkl-alert-message--error {
    border-style: double;
    border-width: 4px;
  }
}

@keyframes jkl-dismiss-u58pgjv {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }
}
/*# sourceMappingURL=index.f7fe7618.css.map */
